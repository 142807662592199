<template>
    <div class="overflow-hidden mr-5 ml-5">
        <div ref="scrollWrapper" class="scrolling-wrapper flex overflow-x-auto">
            <div v-for="member in scrollingMembers" :key="member.shirtNumber"
                class="member snap-center flex-none whitespace-nowrap mx-2">
                <div class="left-sleeve"></div>
                <div class="right-sleeve"></div>
                <div class="number">{{ member.shirtNumber }}</div>
                <div class="name">{{ member.firstName }}</div>
                <div class="hem"></div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import Member from '@/types/Member';

export default defineComponent({
    name: 'TeamJersey',
    props: {
        members: {
            type: Array as PropType<Member[]>,
            required: true
        }
    },
    data() {
    return {
      scrollingMembers: [] as Member[]
    }
  },
  watch: {
    members: {
      immediate: true,
      handler(newVal: Member[]) {
        this.scrollingMembers = [...newVal, ...newVal];
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.startScrolling();
    });
  },
  methods: {
  startScrolling() {
    let scrollWrapper = this.$refs.scrollWrapper as HTMLElement;
    if (!scrollWrapper) {
      console.error('Element not found');
      return;
    }

    let scrollSpeed = 1;
    let scrollPos = 0;

    setInterval(() => {
      scrollPos += scrollSpeed;

      if (scrollPos >= scrollWrapper.scrollWidth / 2) {
        scrollPos = 0;
      }

      scrollWrapper.scrollLeft = scrollPos;
    }, 20);
  }
}
});
</script>

<style scoped>
.member {
  position: relative;
  width: 200px;
  height: 250px;
  background-color: #007bff;
  border-radius: 0 0 10px 10px;
  overflow: hidden;
  margin-top: 40px;
  white-space: nowrap;
  display: inline-block;
}

.member::before {
  content: '';
  position: absolute;
  top: -40px;
  left: 50%;
  transform: translateX(-50%);
  width: 80px;
  height: 80px;
  background-color: #1e293b;
  border-radius: 50%;
  border: 2px solid #000;
  border-bottom: none;
}

.member::after {
  content: '';
  position: absolute;
  top: 40px;
  left: 50%;
  transform: translateX(-50%);
  width: 240px;
  height: 120px;
  background-color: #007bff;
  border: 2px solid #000;
  border-radius: 0 0 30px 30px;
  z-index: -1;
}

.member .left-sleeve {
  position: absolute;
  top: 40px;
  left: -60px;
  width: 100px;
  height: 100px;
  background-color: #007bff;
  border: 2px solid #000;
  border-radius: 0 50px 0 0;
  transform: rotate(-45deg);
}

.member .right-sleeve {
  position: absolute;
  top: 40px;
  right: -60px;
  width: 100px;
  height: 100px;
  background-color: #007bff;
  border: 2px solid #000;
  border-radius: 50px 0 0 0;
  transform: rotate(45deg);
}

.number {
  font-size: 2em;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
}

.name {
  font-size: 1em;
  position: absolute;
  bottom: 10px;
  width: 100%;
  text-align: center;
  color: #fff;
}

.scrolling-wrapper {
  overflow-x: hidden;
}
</style>
