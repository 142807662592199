import axios from 'axios';
import Member from '@/types/Member';

export default{
    async fetchMembers(){
        const baseUrl = "https://api.drunkenhippos.de";
        try{
            const members = await axios.get(baseUrl + '/members');
            const result = [] as Member[];
            members.data.forEach((member: Member) => {
                if(member.shirtNumber != 0){
                    result.push(member);
                }
            });

            return result;
        }catch(error){
            console.error('Es gab ein Problem beim Abrufen der Mitglieder:', error);
            return [];
        }
    }
}