import axios from 'axios';
import ChartData from '@/types/ChartData';

export default{
    async fetchPieChartData(){
        const baseUrl = "https://api.drunkenhippos.de";
        try{
            const data = {} as ChartData;
            const punishments = await axios.get(baseUrl + '/punishments/summary');
            const presences = await axios.get(baseUrl + '/presences/summary');
            const punishmentCategories = await axios.get(baseUrl + '/punishments/categories');
            const subsidies = await axios.get(baseUrl + '/subsidies');

            data.punishmentSeries = punishments.data;
            data.presenceSeries = presences.data;
            data.punishmentCategories = punishmentCategories.data;
            data.subsidies = subsidies.data;
            return data;
        }catch(error){
            console.error('Es gab ein Problem beim Abrufen der Chart-Daten:', error);
            return [];
        }
    }

}