<template>
    <div>
      <apexchart type="line" :options="chartOptions" :series="series"></apexchart>
    </div>
  </template>
  
  <script lang="ts">
  import { defineComponent, onMounted, ref } from 'vue';
  import VueApexCharts from 'vue3-apexcharts';
  import chartDataService from '@/services/ChartDataService';
  import ChartData from '@/types/ChartData';

  export default defineComponent({
    name: 'CashChart',
    components: {
      apexchart: VueApexCharts,
    },
    setup() {
      const chartOptions = ref({
        chart:{
            dropShadow: {
            enabled: true,
            color: '#000',
            top: 18,
            left: 7,
            blur: 10,
            opacity: 0.2
          },
        },
        xaxis: {
          categories: ["Januar"] as string[],
          labels:{
            style:{
              colors: '#ffffff'
            }
          },
          title: {
            text: 'Monate',
            style:{
                color: '#ffffff'
            }
          }
        },
        yaxis: {
          title: {
            text: 'Subventionen in €',
            style:{
                color: '#ffffff'
            }
          },
          labels:{
            style:{
              colors: '#ffffff'
            }
          }
        },
        title:{
            text: 'Subventionen pro Monat',
            align: 'center',
            margin: 15,
            style: {
              fontSize:  '14px',
              color:  '#ffffff'
            }
        },
        dataLabels: {
          enabled: true,
        },
        stroke: {
          curve: 'smooth'
        },
        markers: {
          size: 1
        }
      });
      
      const series = ref([
        {
          name: "Cash",
          data: [] as number[],
        }
      ]);
  
    onMounted(async () => {
        const data = await chartDataService.fetchPieChartData() as ChartData;
        series.value[0].data = data.subsidies.map(subsidy => subsidy.value / 100);

        var months = data.subsidies.map(subsidy => subsidy.month);
        months.forEach((element) => {
          if(chartOptions.value.xaxis.categories.includes(element)){
            return;
          }

          var month = element as string;
          chartOptions.value.xaxis.categories.push(month);
        });
    });

      return { chartOptions, series };
    }
  });
  </script>
  