import axios from 'axios';
import Rules from '@/types/Rules';

export default{
    async fetchRules(){
        const baseUrl = "https://api.drunkenhippos.de";
        try{
            const publicRules = await axios.get(baseUrl + '/rules/public');
            const gameRules = await axios.get(baseUrl + '/rules/game');

            return {
                publicRules: publicRules.data,
                gameRules: gameRules.data
            } as Rules;
        }catch(error){
            console.error('Es gab ein Problem beim Abrufen der Mitglieder:', error);
            return [];
        }
    }
}