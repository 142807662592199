<template>
    <div>
      <apexchart
        v-if="punishmentSeries.length > 0"
        type="donut"
        :options="punishmentChartOptions"
        :series="punishmentSeries"
      ></apexchart>
      <p v-else>Lade Daten...</p>
    </div>
  </template>
  
  <script lang="ts">
  import { defineComponent, onMounted, ref } from 'vue'
  import chartDataService from '@/services/ChartDataService';
  import ChartData from '@/types/ChartData';
  
  export default defineComponent({
    name: 'PunishmentChart',
    setup() {
      const punishmentSeries = ref<number[]>([]);
      const punishmentChartOptions = ref({
        colors: [],
        labels: ["Lade Daten..."],
        title: {
          text: 'Gezahlte Strafen',
          align: 'center',
          margin: 15,
          style: {
            fontSize:  '14px',
            color:  '#ffffff'
          }
        },
        legend: {
          position: 'bottom',
          horizontalAlign: 'center',
          labels:{
            colors: '#ffffff'
          }
        },
      });
  
      onMounted(async () => {
        const data = await chartDataService.fetchPieChartData() as ChartData;
        //punishmentSeries.value = data.punishmentSeries;
        // select all name properties from data.punishmentSeries object and assign them to punishmentSeries
        punishmentSeries.value = data.punishmentSeries.map(punishment => punishment.value / 100);
        punishmentChartOptions.value.labels = data.punishmentSeries.map(punishment => punishment.name);
        punishmentChartOptions.value.colors = data.punishmentSeries.map(punishment => punishment.color) as never[];
      });
  
      return { punishmentSeries, punishmentChartOptions };
    }
  })
  </script>
  