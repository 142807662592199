<template>
    <div>
      <apexchart type="radar" :options="chartOptions" :series="series"></apexchart>
    </div>
  </template>
  
  <script lang="ts">
  import { defineComponent, onMounted, ref } from 'vue';
  import VueApexCharts from 'vue3-apexcharts';
  import ChartDataService from '@/services/ChartDataService';
  import ChartData from '@/types/ChartData';
  
  export default defineComponent({
    name: 'PunishmentCategoriesChart',
    components: {
      apexchart: VueApexCharts,
    },
    setup() {
      const chartOptions = ref({
        chart: {
          type: 'radar',
          animations: {
            enabled: true,
            easing: 'easeinout',
            speed: 800,
            animateGradually: {
                enabled: true,
                delay: 150
            },
            dynamicAnimation: {
                enabled: true,
                speed: 350
            }
          }
        },
        title: {
          text: 'Arten von Strafen',
          align: 'center',
          margin: 15,
          style: {
            fontSize:  '14px',
            color:  '#ffffff'
          }
        },
        xaxis: {
          categories: [],
          labels:{
            style:{
              colors: ['#ffffff']
            }
          }
        },
        dataLabels: {
          enabled: true,
          background: {
            enabled: true,
            borderRadius:2,
          }
        },
        fill:{
          opacity: 0.5,
        },
        markers: {
          size: 5,
          hover: {
            size: 10
          }
        }
      });
      const series = ref([]);
  
      onMounted(async () => {
        const data = await ChartDataService.fetchPieChartData() as ChartData;
        var categories:string[] = [];
        var seriesData:number[] = [];

        categories = data.punishmentCategories.map(punishment => punishment.type);
        seriesData = data.punishmentCategories.map(punishment => punishment.value / 100);
        
        categories.forEach((element) => {
          var category = element as never;
          chartOptions.value.xaxis.categories.push(category);
        });

        series.value = [{
            name: 'Strafen',
            data: seriesData
            }] as never[];
      });
  
      return { chartOptions, series };
    }
  });
  </script>
  