import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_apexchart = _resolveComponent("apexchart")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.punishmentSeries.length > 0)
      ? (_openBlock(), _createBlock(_component_apexchart, {
          key: 0,
          type: "donut",
          options: _ctx.punishmentChartOptions,
          series: _ctx.punishmentSeries
        }, null, 8, ["options", "series"]))
      : (_openBlock(), _createElementBlock("p", _hoisted_1, "Lade Daten..."))
  ]))
}