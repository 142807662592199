<template>
  <div class="home bg-white dark:bg-slate-800" @click="burgerMenu = false">
    <div class="w-full h-12 shadow-md">
      <div class="flex justify-center mt-4">
        <a href="#" @click.stop @click="burgerMenu = !burgerMenu" class="mx-2">
          <span class="[&>svg]:h-7 [&>svg]:w-7 [&>svg]:fill-[#ffffff]">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="#ffffff">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
            </svg>
          </span>
        </a>

        <transition name="slide-fade">
          <div v-if="burgerMenu"
               @click.stop
               class="fixed top-0 left-0 w-64 h-full bg-blue-900 shadow-lg z-50 overflow-y-auto transition-transform duration-200 ease-in-out transform translate-x-0">
            <button @click.stop="burgerMenu = false" class="absolute top-0 right-0 m-2">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x cursor-pointer hover:text-red-400 rounded-full w-5 h-5">
                <line x1="18" y1="6" x2="6" y2="18"></line>
                <line x1="6" y1="6" x2="18" y2="18"></line>
              </svg>
            </button>
            <RouterLink to="/" class="block px-4 py-2 hover:bg-indigo-500 text-white mt-10">Home</RouterLink>
            <RouterLink to="/rules" class="block px-4 py-2 hover:bg-indigo-500 text-white mt-1">Regeln</RouterLink>
            <RouterLink to="/impressum" class="fixed bottom-0 left-0 w-64 mb-5 text-center text-white">Impressum</RouterLink>
          </div>
        </transition>

        <a href="https://www.instagram.com/drunkenhippos?igsh=YzQzMzg5aXRhenBi&utm_source=qr" target="_blank"
           class="mx-2">
          <span class="[&>svg]:h-7 [&>svg]:w-7 [&>svg]:fill-[#c13584]">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
              <path
                  d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z" />
            </svg>
          </span>
        </a>

        <a href="https://www.facebook.com/profile.php?id=61558399793569" target="_blank" class="mx-2">
          <span class="[&>svg]:h-7 [&>svg]:w-7 [&>svg]:fill-[#1877f2]">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
              <path
                  d="M80 299.3V512H196V299.3h86.5l18-97.8H196V166.9c0-51.7 20.3-71.5 72.7-71.5c16.3 0 29.4 .4 37 1.2V7.9C291.4 4 256.4 0 236.2 0C129.3 0 80 50.5 80 159.4v42.1H14v97.8H80z" />
            </svg>
          </span>
        </a>

        <a href="https://shop.teamshirts.de/drunkenhippos/products?fbclid=PAZXh0bgNhZW0CMTEAAabR8ur4sRiLXPrXwDjpZNJi_UCJTSPTq9B0975e_y6klU6Zk4_das6KHJY_aem_ATww9Bq2I7yQFwLfkjZAfTvhm1BRoKtsPi7i7jZCql55n8fqirivXvzLhmoMgo8qkXBKsTXgEgHOb1vI2Ekl3F_h"
           target="_blank" class="mx-2">
          <span class="[&>svg]:h-7 [&>svg]:w-7 [&>svg]:fill-[#ffffff]">
            <svg width="800px" height="800px" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
              <title>ionicons-v5-q</title>
              <path
                  d="M314.56,48S291.78,56,256,56s-58.56-8-58.56-8a31.94,31.94,0,0,0-10.57,1.8L32,104l16.63,88,48.88,5.52A24,24,0,0,1,118.8,222.1L112,464H400l-6.8-241.9a24,24,0,0,1,21.29-24.58L463.37,192,480,104,325.13,49.8A31.94,31.94,0,0,0,314.56,48Z"
                  style="fill:none;stroke:#ffffff;stroke-linecap:round;stroke-linejoin:round;stroke-width:32px" />
              <path d="M333.31,52.66a80,80,0,0,1-154.62,0"
                    style="fill:none;stroke:#ffffff;stroke-linecap:round;stroke-linejoin:round;stroke-width:32px" />
            </svg>
          </span>
        </a>
      </div>
    </div>
  </div>

  <div class="flex justify-center space-x-4 p-4">
    <div class="w-full md:w-1/2 p-4 bg-white rounded-lg shadow-lg">
      <h2 class="text-xl font-semibold mb-4">Allgemeine Regeln</h2>
      <ul class="space-y-2">
        <li v-for="rule in rules.publicRules" :key="rule.id" class="p-2 bg-gray-100 rounded-md shadow-sm">
          {{ rule.ruleDescription }}
        </li>
      </ul>
    </div>
  </div>

  <div class="flex justify-center space-x-4 p-4">
    <div class="w-full md:w-1/2 p-4 bg-white rounded-lg shadow-lg">
      <h2 class="text-xl font-semibold mb-4">Spielregeln</h2>
      <ul class="space-y-2">
        <li v-for="rule in rules.gameRules" :key="rule.id" class="p-2 bg-gray-100 rounded-md shadow-sm">
          {{ rule.ruleDescription }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import RuleService from '@/services/RuleService';
import Rules from '@/types/Rules';
import CashChart from "@/components/CashChart.vue";
import PresenceChart from "@/components/PresenceChart.vue";
import TeamJersey from "@/components/TeamJersey.vue";
import PunishmentChart from "@/components/PunishmentChart.vue";
import PunishmentCategoriesChart from "@/components/PunishmentCategoriesChart.vue";

export default defineComponent({
  name: 'RuleView',
  data() {
    return {
      burgerMenu: false
    }
  },
  setup() {
    const rules = ref<Rules>({
      publicRules: [],
      gameRules: []
    });

    RuleService.fetchRules().then((data) => {
      rules.value = data as Rules;
    });

    return { rules };
  }
});
</script>

<style scoped>
.slide-fade-enter-active {
  transition: all .3s ease;
}

.slide-fade-leave-active {
  transition: all .3s ease;
}

.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(-100%);
}

.side-nav{
  background-color: #314361;
  color: #ffffff;
}
</style>