<template>
    <div>
      <apexchart
        v-if="presenceSeries.length > 0"
        type="donut"
        :options="presenceOptions"
        :series="presenceSeries"
      ></apexchart>
      <p v-else>Lade Daten...</p>
    </div>
  </template>
  
  <script lang="ts">
  import { defineComponent, onMounted, ref } from 'vue'
  import chartDataService from '@/services/ChartDataService';
  import ChartData from '@/types/ChartData';
  
  export default defineComponent({
    name: 'PresenceChart',
    setup() {
      const presenceSeries = ref<number[]>([]);
      const presenceOptions = ref({
        colors: [],
        labels: ["Lade Daten..."],
        title: {
          text: 'Anwesenheiten',
          align: 'center',
          margin: 15,
          style: {
            fontSize:  '14px',
            color:  '#ffffff'
          }
        },
        legend: {
          position: 'bottom',
          horizontalAlign: 'center',
          labels:{
            colors: '#ffffff'
          }
        }
      });
  
      onMounted(async () => {
        const data = await chartDataService.fetchPieChartData() as ChartData;
        presenceSeries.value = data.presenceSeries.map(presence => presence.value);
        presenceOptions.value.labels = data.presenceSeries.map(presence => presence.name);
        presenceOptions.value.colors = data.presenceSeries.map(presence => presence.color) as never[];
      });
  
      return { presenceSeries, presenceOptions };
    }
  })
  </script>
  