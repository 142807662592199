<template>
    <div class="impressum">
      <h1>Impressum</h1>
      <p>
        Angaben gemäß § 5 TMG
      </p>
      <p>
        Roman Herbstmann<br>
        Tungerloh-Capellen 3<br>
        48712 Gescher<br>
        Deutschland
      </p>
      <p>
        <strong>Vertreten durch:</strong><br>
        Roman Herbstmann
      </p>
      <p>
        <strong>Kontakt:</strong><br>
        Tel: +49 (0)17655401011<br>
        E-Mail: rh@rherbstmann.de
      </p>
        <p>
            <strong>Umsatzsteuer-ID:</strong><br>
            Umsatzsteuer-Identifikationsnummer entfällt gemäß §19 Umsatzsteuergesetz
        </p>
    </div>
  </template>
  
  <script lang="ts">
  export default {
    name: 'ImpressumView'
  }
  </script>
  
  <style scoped>
  .impressum {
    padding: 20px;
    color: #fff;
  }

h1{
    margin-bottom: 4rem;
}

  p{
    margin-bottom: 2rem;
  }

  p strong{
    font-weight: bold;
  }
  </style>